<!-- eslint-disable vue/no-use-v-if-with-v-for -->
<template>
  <div class="admin-users">
    <div class="title">
      <h1>{{ $t('admin.users.title') }}</h1>
    </div>
    <div class="content">
      <div class="flexbox">
        <div class="left col-pc-6 v-step-user-approval">
          <div class="subtitle">
            <h3>{{ $t('admin.users.awaiting-approval') }}</h3>
          </div>
          <div class="boxes">
            <ul class="col-pc-12 ul-boxes">
              <li v-if="awaitingApproval.length === 0">
                <h4 class="uk-text-left uk-text-uppercase">{{ $t('admin.users.no-request-awaiting-approval') }}</h4>
              </li>
              <li
                v-for="member in awaitingApproval"
                :key="member.id"
                class="awaiting-approval-box col-mobile-12 col-pc-12"
              >
                <div class="li-content col-all-12">
                  <div class="name col-pc-9 col-mobile-12">
                    <div class="text">
                      <h2 v-if="member.user.username" :uk-tooltip="member.user.username">{{ member.user.email }}</h2>
                      <h2 v-if="!member.user.username">{{ member.user.email }}</h2>
                    </div>
                  </div>
                  <div class="buttons col-pc-3 col-mobile-12">
                    <div class="approve col-pc-6 col-mobile-6">
                      <button class="success-full col-mobile-12" @click="approveMember(member.id)">
                        <i class="fas fa-check"></i>
                      </button>
                    </div>
                    <div class="decline col-pc-6 col-mobile-6">
                      <button class="danger-full col-mobile-12" @click="removeMember(member)">
                        <i class="fas fa-times"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="right col-pc-6 v-step-community-members">
          <div class="subtitle">
            <h3>{{ $t('admin.users.community-members') }}</h3>
          </div>
          <div class="boxes">
            <ul class="col-pc-12 ul-boxes">
              <li v-for="member in allCommunityMembers" :key="member.id" class="user-box col-mobile-12 col-pc-12">
                <div class="li-content col-all-12">
                  <div class="name col-pc-9 col-mobile-9">
                    <div class="text col-pc-10 col-mobile-12">
                      <h2 v-if="member.user.username" :uk-tooltip="member.user.username">{{ member.user.email }}</h2>
                      <h2 v-if="!member.user.username">{{ member.user.email }}</h2>
                      <h2 v-if="member.user.id === $store.state.auth.user.id" style="margin-left: 10px;">
                        ({{ $t('admin.users.you') }})
                      </h2>
                    </div>

                    <div
                      v-if="
                        checkPermissions()(member.permissionsValue, 'owner') ||
                          checkPermissions()(member.permissionsValue, 'admin')
                      "
                      class="role col-pc-2 col-mobile-2"
                    >
                      <div class="administrator user-icon">
                        <i
                          v-if="checkPermissions()(member.permissionsValue, 'owner')"
                          class="fas fa-crown"
                          :uk-tooltip="$t('admin.users.role.owner')"
                        ></i>
                        <i
                          v-if="
                            !checkPermissions()(member.permissionsValue, 'owner') &&
                              checkPermissions()(member.permissionsValue, 'admin')
                          "
                          class="fas fa-shield-alt"
                          :uk-tooltip="$t('admin.users.role.admin')"
                        ></i>
                      </div>
                    </div>
                  </div>
                  <div class="buttons col-pc-3 col-mobile-12">
                    <div class="user-settings col-pc-6 col-mobile-6">
                      <button
                        class="warning-full col-mobile-12"
                        :disabled="
                          checkPermissions()(member.permissionsValue, 'owner') ||
                            member.id === $store.state.selection.community.id
                        "
                        @click="
                          userSettingOpenned === member.id
                            ? (userSettingOpenned = '')
                            : (userSettingOpenned = member.id)
                        "
                        :class="{ active: userSettingOpenned === member.id }"
                      >
                        <i class="fas fa-user-cog"></i>
                      </button>
                    </div>
                    <div class="user-kick col-pc-6 col-mobile-6">
                      <button
                        class="danger-full col-mobile-12"
                        :disabled="
                          checkPermissions()(member.permissionsValue, 'owner') ||
                            (checkCurrentPermissions()('admin') &&
                              !checkCurrentPermissions()('owner') &&
                              checkPermissions()(member.permissionsValue, 'admin')) ||
                            member.id === $store.state.selection.community.id
                        "
                        @click="removeMember(member)"
                      >
                        <i class="fas fa-user-times"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <div
                  class="user-settings-dropdown"
                  v-if="userSettingOpenned === member.id && !checkPermissions()(member.permissionsValue, 'owner')"
                >
                  <ol>
                    <li>
                      <p>{{ $t('admin.users.dispatch') }}</p>
                      <toggle-button
                        id="admin-users-dispatch"
                        :value="checkPermissions()(member.permissionsValue, 'dispatch')"
                        :labels="{
                          checked: $t('panic-buttons-dispatch_component.on'),
                          unchecked: $t('panic-buttons-dispatch_component.off')
                        }"
                        @change="updateMemberPermissions(member, 'dispatch')"
                      ></toggle-button>
                    </li>
                    <li>
                      <p>{{ $t('admin.users.police') }}</p>
                      <toggle-button
                        id="admin-users-police"
                        :value="checkPermissions()(member.permissionsValue, 'police')"
                        :labels="{
                          checked: $t('panic-buttons-dispatch_component.on'),
                          unchecked: $t('panic-buttons-dispatch_component.off')
                        }"
                        @change="updateMemberPermissions(member, 'police')"
                      ></toggle-button>
                    </li>
                    <li>
                      <p>{{ $t('admin.users.civilian') }}</p>
                      <toggle-button
                        id="admin-users-civilian"
                        :value="checkPermissions()(member.permissionsValue, 'civilian')"
                        :labels="{
                          checked: $t('panic-buttons-dispatch_component.on'),
                          unchecked: $t('panic-buttons-dispatch_component.off')
                        }"
                        @change="updateMemberPermissions(member, 'civilian')"
                      ></toggle-button>
                    </li>
                    <li v-if="checkCurrentPermissions()('owner')" class="li-admin">
                      <p>{{ $t('admin.users.admin') }}</p>
                      <toggle-button
                        id="admin-users-admin"
                        :value="checkPermissions()(member.permissionsValue, 'admin')"
                        :labels="{
                          checked: $t('panic-buttons-dispatch_component.on'),
                          unchecked: $t('panic-buttons-dispatch_component.off')
                        }"
                        @change="updateMemberPermissions(member, 'admin')"
                      ></toggle-button>
                    </li>
                    <li
                      v-if="checkCurrentPermissions()('owner') && $store.state.auth.user.email.includes('leafcad')"
                      class="li-admin"
                    >
                      <p>{{ $t('admin.users.owner') }}</p>
                      <toggle-button
                        id="admin-users-owner"
                        :value="checkPermissions()(member.permissionsValue, 'owner')"
                        :labels="{
                          checked: $t('panic-buttons-dispatch_component.on'),
                          unchecked: $t('panic-buttons-dispatch_component.off')
                        }"
                        @change="updateMemberPermissions(member, 'owner')"
                      ></toggle-button>
                    </li>
                  </ol>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="less" scoped src="@/assets/less/dashboards/user-interfaces.less"></style>

<script>
import { mapGetters } from 'vuex'
import { debounce } from 'lodash'

export default {
  name: 'general',
  data() {
    return {
      userSettingOpenned: '',
      awaitingApproval: [],
      communityMembers: []
    }
  },
  apollo: {
    awaitingApproval: {
      query: require('@/graphql/admin/fetchAwaitingCommunityMembers.gql'),
      variables() {
        return {
          communityId: this.$store.state.selection.community.community.id
        }
      },
      update: data => data.community.members,
      fetchPolicy: 'no-cache',
      subscribeToMore: {
        document: require('@/graphql/admin/subscribeToAwaitingCommunityMember.gql'),
        variables() {
          return {
            communityId: this.$store.state.selection.community.community.id
          }
        },
        updateQuery(previousResult, { subscriptionData }) {
          const member = subscriptionData.data.communityMember

          if (member.mutation === 'CREATED') {
            if (!member.node.permissions.map(perm => perm.name).includes('accepted')) {
              return this.addMemberToArray(previousResult.community.members, member.node)
            }
          }

          if (member.mutation === 'UPDATED') {
            if (member.node.permissions.map(perm => perm.name).includes('accepted')) {
              return this.removeMemberFromArray(previousResult.community.members, member.previousValues.id)
            }

            return this.updateMemberInArray(previousResult.community.members, member.node)
          }

          if (member.mutation === 'DELETED') {
            return this.removeMemberFromArray(previousResult.community.members, member.previousValues.id)
          }
        }
      }
    },
    communityMembers: {
      query: require('@/graphql/admin/fetchCommunityMembers.gql'),
      variables() {
        return {
          communityId: this.$store.state.selection.community.community.id
        }
      },
      update: data => data.community.members,
      fetchPolicy: 'no-cache',
      subscribeToMore: {
        document: require('@/graphql/admin/subscribeToCommunityMember.gql'),
        variables() {
          return {
            communityId: this.$store.state.selection.community.community.id
          }
        },
        updateQuery(previousResult, { subscriptionData }) {
          const member = subscriptionData.data.communityMember

          let isAccepted = false
          if (member.node) {
            isAccepted = member.node.permissions.map(perm => perm.name).includes('accepted')
          }

          if (member.mutation === 'CREATED' && isAccepted) {
            return this.addMemberToArray(previousResult.community.members, member.node)
          }

          if (member.mutation === 'UPDATED') {
            if (
              previousResult.community.members.filter(theMember => theMember.id === member.node.id).length === 0 &&
              isAccepted
            ) {
              return this.addMemberToArray(previousResult.community.members, member.node)
            }

            if (!isAccepted) {
              return this.removeMemberFromArray(previousResult.community.members, member.previousValues.id)
            }

            return this.updateMemberInArray(previousResult.community.members, member.node)
          }

          if (member.mutation === 'DELETED') {
            return this.removeMemberFromArray(previousResult.community.members, member.previousValues.id)
          }
        }
      }
    }
  },
  computed: {
    allCommunityMembers() {
      return this.communityMembers.filter(
        member => !(process.env.NODE_ENV === 'production' && member.user.email.includes('leafcad'))
      )
    }
  },
  methods: {
    ...mapGetters('auth', ['checkPermissions', 'checkCurrentPermissions']),
    addMemberToArray(theArray, toAdd) {
      return {
        community: {
          __typename: 'Community',
          members: [...theArray, toAdd]
        }
      }
    },
    updateMemberInArray(theArray, toUpdate) {
      return {
        community: {
          __typename: 'Community',
          members: theArray.map(theMember => (theMember.id === toUpdate.id ? toUpdate : theMember))
        }
      }
    },
    removeMemberFromArray(theArray, toRemove) {
      return {
        community: {
          __typename: 'Community',
          members: theArray.filter(theMember => theMember.id !== toRemove)
        }
      }
    },
    approveMember(memberId) {
      this.$store
        .dispatch('admin/updateCommunityMember', {
          communityMemberId: memberId,
          data: {
            permissions: {
              connect: {
                name: 'accepted'
              }
            }
          }
        })
        .catch(error => {
          this.$store.dispatch('errorManager/showError', { error: error })
        })
    },
    removeMember(member) {
      this.$swal({
        title: this.$t('admin.users.remove-dialog.title', {
          user: member.user.username ? member.user.username : member.user.email
        }),
        text: this.$t('admin.users.remove-dialog.text', {
          user: member.user.username ? member.user.username : member.user.email
        }),
        type: 'warning',
        focusCancel: true,
        showCancelButton: true,
        confirmButtonText: this.$t('admin.users.remove-dialog.confirm')
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('admin/deleteCommunityMember', member.id).catch(error => {
            this.$store.dispatch('errorManager/showError', { error: error })
          })
        }
      })
    },
    updateMemberPermissions(member, permission) {
      let action = {}

      if (this.checkPermissions()(member.permissionsValue, permission)) {
        action = {
          disconnect: {
            name: permission
          }
        }
      } else {
        action = {
          connect: {
            name: permission
          }
        }
      }

      this.$store
        .dispatch('admin/updateCommunityMember', {
          communityMemberId: member.id,
          data: {
            permissions: action
          }
        })
        .then(() => {
          this.debouncedSaveNotification()
        })
        .catch(error => {
          this.$store.dispatch('errorManager/showError', { error: error })
        })
    },
    debouncedSaveNotification: debounce(function() {
      this.$notify({
        type: 'success',
        title: this.$t('notifications.save.title'),
        text: this.$t('notifications.save.text')
      })
    }, 500)
  },
  mounted() {
    this.$apollo.queries.awaitingApproval.refetch()
    this.$apollo.queries.communityMembers.refetch()
  }
}
</script>
